import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Remark from "../components/remark"
import Image from "../components/image"
import SEO from "../components/seo"

// TODO
//   Headings for blog, publications, resume.
// 
const IndexPage = () => (
  <Layout>
    <div className='blog'>
      <SEO title="Home" />

      <a href='/'>Writing</a>
      {" | "}
      <a href='/publications'>Publications</a>
      {" | "}
      <a href='https://docsend.com/view/gqvmdizveh8x396e'>Resume</a>
      {" | "}
      <a href='https://gitlab.com/brandonballinger'>Gitlab</a>

      <p>
        Hi! I&apos;m Brandon. I'm a startup founder and engineering manager, currently building a new startup
        in consumer health. I previously co-founded
        {' '}<a href="https://cardiogram.com">Cardiogram</a>,
        {' '}<a href="https://sift.com"> Sift</a>,
        and worked at Brex, the
        {' '}<a href="https://www.wired.com/2014/06/healthcare-gov-revamp/">
          HealthCare.gov rescue team
        </a>, and Google on
        {' '} <a href="https://research.google/pubs/pub36756/">
          speech recognition for Android.
        </a>
      </p>

      <h2>Applied Artificial Intelligence</h2>
      <Remark>
        I've spent much of my career on applied AI, building both machine learning algorithms as well
        as web and mobile apps that make them user-friendly.
      </Remark>

      <ul>
        <li>
          <a href="https://blog.cardiogr.am/three-challenges-for-artificial-intelligence-in-medicine-dfb9993ae750">
            Three challenges for AI in Medicine
          </a>
          <Remark> (Cardiogram blog)</Remark>
        </li>
        <li>
          <a href="https://www.oreilly.com/library/view/oreilly-artificial-intelligence/9781491985250/video314929.html">
            Deep learning with limited labeled data
          </a>
          <Remark> (O&apos;Reilly AI Conf, 2017)</Remark>
        </li>
        <li>
          <a href="https://www.oreilly.com/library/view/strata-hadoop/9781491944608/video244361.html">
            Can deep neural networks save your neural network? Artificial intelligence, sensors, and strokes.
          </a>
          <Remark> (Strata Conf, 2016)</Remark>
        </li>

        <li>
          <a href="https://cdn.oreillystatic.com/en/assets/1/event/100/Deep%20Learning%20and%20the%20Dream%20of%20AI%20Presentation.pdf">
            Deep learning and the dream of AI
          </a>
          <Remark> (Strata Conf, 2013)</Remark>
        </li>

        <li>
          <Link to="https://www.quora.com/Why-are-we-experiencing-such-an-explosive-growth-of-machine-learning-and-its-applications-today-even-though-the-space-exists-for-more-than-3-4-decades">
            Why is machine learning experiencing such explosive growth today?
          </Link>
          <Remark> (Quora) </Remark>
        </li>

        <li>
          <Link to="https://www.quora.com/How-does-one-operationalize-a-machine-learning-ranking-system-from-an-organization-and-an-engineering-management-point-of-view-How-do-organizations-make-sure-that-theyre-running-smoothly-and-improving-How-are-goals-defined-and-by-whom">
            How does one operationalize a machine learning ranking system, from an organization and an engineering management point of view?
          </Link>
          <Remark> (Quora) </Remark>
        </li>

        <li>
          <Link to="https://future.a16z.com/podcasts/ai-medicine/">
            AI in Medicine, in Practice
          </Link>
          <Remark> (a16z podcast) </Remark>
        </li>
      </ul>

      <h2>Startups</h2>
      <Remark>I helped start two startups, Cardiogram and Sift Science. 
      Some lessons learned:</Remark>

      <ul>
        <li><Link to="https://ph.brandonb.cc/no-filter-the-meanest-thing-paul-graham-said-to-a-startup">No filter: the meanest thing Paul Graham said to a startup</Link></li>
        <li><Link to="https://ph.brandonb.cc/what-happened-when-my-co-founder-quit-the-night-before-our-yc-interview">What happened when my co-founder quit the night before our YC interview</Link></li>
        <li><Link to="https://ph.brandonb.cc/enterprise-sales-tips-for-hackers">Enterprise Sales Tips for Hackers</Link></li>
        <li><Link to="https://ph.brandonb.cc/is-your-b2b-startup-new-enterprise-or-old-enterprise">Is your B2B startup New Enterprise or Old Enterprise?</Link></li>
        <li><Link to="https://ph.brandonb.cc/advice-for-cs-students-considering-a-startup">Advice for CS Students Considering a Startup</Link></li>
        <li><Link to="https://www.dropbox.com/s/u4408mzze7f67te/AHA-2017-Invited-Talk-Bringing-An-App-To-Market.pdf?dl=0">How to bring a mobile app to market</Link> <Remark> (American Heart Association invited talk, 2017) </Remark></li>
      </ul>

      <h2>Everything else</h2>
      <ul>
        <li>
          <Link to="https://a16z.com/2017/03/10/healthcare-reform-history-tradeoffs/">Why is healthcare reform so complicated?</Link>
        </li>
        <li>
          <Link to="https://www.dropbox.com/s/db0pw7qni9rvr1d/CSE390l.pdf?dl=0">CSE 390L guest lecture</Link> <Remark>(PDF)</Remark>
        </li>
      </ul>
    </div>
  </Layout>
)

export default IndexPage
